export interface SerializedCartItem{
	product_id: number,
	granel: boolean,
	quantity: number,
}

export interface CartItem{
	product_id: number,
	product_name: string,
	size: string,
	price: number,
	price_discounted?: number,
	price_gram?: number,
	quantity: number,
}

export interface APIResponse<T>{
	error: boolean,
	code: string,
	message: string,
	data?: T
}

export interface WebCategory{
	category_id: number,
	category_name: string,
	priority: number,
}

export interface ShopPayload{
	query?: string,
	page_title: string,
	page_description: string,
	filters: {
		filter_name: string,
		filter_values: string[]
	}[],
	products: {
		product_id: number,
		product_name: string,
		price: number,
		price_discounted?: number,
		price_gram?: number,
		size: string,
		message: string,
		images: string[],
		categories: number[],
	}[],
	categories: {
		category_id: number,
		category_name: string,
	}[]
}

export interface WebProduct{
	product_id: number,
	product_name: string,
	price: number,
	price_discounted?: number,
	price_gram?: number,
	size: string,
	message: string,
	description: string,
	images: string[],
	categories: {
		category_id: number,
		category_name: string,
		main: boolean,
	}[],
	metadata: {
		metadata_id: number,
		metadata_name: string,
		metadata_value_id: number,
		metadata_value: string,
	}[]
}

export interface ShippingMethod{
	method_id: number,
	name: string,
	cost: number,
	description?: string,
	local?: boolean,
	needs_location?: boolean,
}

export interface ShopVariables{
	shipping_limit: number,
	shipping_cost: number,
	shipping_end: number,
	shipping_enabled: boolean,
	announcement: string,
	landing_category: number
	landing_category_title: string,

	pickup_enabled: boolean,
	pickup_start: number,
	pickup_end: number,

	force_openpay: number,
}

export interface WebData{
	variables: ShopVariables,
	landing_products: ShopPayload["products"],
	users: {
		user_id: number,
		first_name: string,
		last_name: string,
		department: string,
	}[]
}

export enum ShippingType{
	SHIPPING = 1,
	PICKUP = 20,
}

export interface InvoiceForm{
	order_hash: string,
	email: string,
	legal_name: string,
	rfc: string,
	regimen: string,
	use_cfdi: string,
	address: Location
}

export interface Location{
	location_id?: number,
	name?: string,
	phone?: string,
	street: string,
	exterior_number: string,
	interior_number?: string,
	neighborhood: string,
	city: string,
	state: string,
	zipcode: string,
	country?: string,
	lattitude?: number,
	longitude?: number,
	place_id?: string,
	comments?: string,
}

export interface Order{
	order_id: number,
	order_hash: string,
	owner_name: string,
	owner_last_name: string,
	owner_email: string,
	owner_phone: string,
	shipping_method: number,
	shipping_method_name: string,
	shipping_method_type: number,
	shipping_method_location_id: number,
	shipping_location_id: number,
	shipping_date: number,
	gift_name: string,
	gift_last_name: string,
	gift_message: string,
	gift_phone?: string,
	shipping_cost: number,
	total: number,
	paid: boolean,
	shipped: boolean,
	prepared: boolean,
	date_created: number,
	shipping_location: Location,
	shipping_method_location: Location,
	items: {
		item_id: number,
		product_id: number,
		product_name: string,
		size: string,
		quantity: number,
		cost_single: number,
		cost: number
	}[],
	payments: {
		payment_id: number,
		payment_method: number,
		method_name: string,
		amount: number,
		paid: boolean,
	}[],
}

export interface SitePage{
	page_id: number,
	page_url: string,
	page_title: string,
	page_subtitle?: string,
	button_text: string,
	button_url: string,
	page_contents?: string,
	page_type: number,
	deleted: boolean,
	user_creator: number,
	user_deleter?: number,
	date_created?: number,
	date_deleted?: number,
	assets?:  SitePageAsset[],
	button?: boolean,
	banner?: string,
}

export enum SitePageType{
	PDF = 1,
	IMAGE_GALLERY = 2,
	TEXT = 3
}

export interface SitePageAssetType{
	type_id: number,
	type_name: string,
}

export enum AssetType{
	PDF = 1,
	IMAGE= 2,
}

export interface SitePageAsset{
	page_id?: number,
	asset_id?: number,
	asset_type: number,
	asset_name: string,
	asset_url: string,
	asset_description?: string | any,
	deleted: boolean,
	user_creator?: number,
	user_deleter?: number,
	date_created?: number,
	date_deleted?: number,
}